import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { useRouter } from 'next/router'

import type { CmsComponentsForAnalytics } from '@cms/analytics/types'
import { getPageSlugFromRouterPath } from '@cms/analytics/utils'
import Stage from '@components/Stage/Stage'
import { useClickEvent } from '@knauf-group/ct-shared-nextjs/web/hooks/useWebAnalytics'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MobileStepper, { mobileStepperClasses } from '@mui/material/MobileStepper'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useStyles } from './SlidingStage.styles'
import type { SlidingStageContentfulProps } from './SlidingStage.types'
import useSlidingStageProps from './useSlidingStageProps'

export const SLIDING_STAGE_CLASS = 'sliding-stage'
const MAX_NUMBER_OF_SLIDES = 5
const AUTOPLAY_DELAY_TIME_IN_MS = 4000

const SlidingStage: React.FC<SlidingStageContentfulProps> = (props) => {
  const { stages } = useSlidingStageProps(props?.fields) || {}
  const isSingleSlide = stages?.length === 1 || stages?.length === 0
  const styles = useStyles(isSingleSlide)
  const { pushSlidingStage: pushClickEvent } = useClickEvent()
  const cmsAnalyticsComponent: CmsComponentsForAnalytics = 'content_stage_sliding_gallery'
  const muiComponentsWithAnalytics: CmsComponentsForAnalytics = 'mui_mobile_stepper'

  const pageName = getPageSlugFromRouterPath(useRouter())
  const onNavigationClick = (actionLabel, componentLabel, targetLabel) => {
    pushClickEvent?.({
      action: actionLabel,
      cms_component: componentLabel,
      target: targetLabel,
      page_name: pageName,
    })
  }

  if (!stages) {
    return null
  }

  return (
    <Box sx={styles.container} data-cy={SLIDING_STAGE_CLASS}>
      <Box position="relative">
        <Swiper
          loop
          navigation={{
            prevEl: `.${SLIDING_STAGE_CLASS}-prev`,
            nextEl: `.${SLIDING_STAGE_CLASS}-next`,
          }}
          pagination={{
            clickable: true,
            el: `.${mobileStepperClasses.dots}`,
          }}
          autoplay={{
            delay: AUTOPLAY_DELAY_TIME_IN_MS,
            pauseOnMouseEnter: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          style={{ height: '100%', width: '100%' }}
          data-cy={`${SLIDING_STAGE_CLASS}-swiper`}
          onNavigationNext={(swiper) =>
            onNavigationClick(
              'arrow',
              `${cmsAnalyticsComponent}_${swiper.activeIndex}`,
              `slider_step_${swiper.activeIndex + 1}`,
            )
          }
          onNavigationPrev={(swiper) =>
            onNavigationClick(
              'arrow',
              `${cmsAnalyticsComponent}_${swiper.activeIndex}`,
              `slider_step_${swiper.activeIndex - 1}`,
            )
          }
        >
          {stages?.map(({ id, ...otherProps }, index) => {
            return (
              <SwiperSlide key={id} data-cy={`${SLIDING_STAGE_CLASS}-swiper-slide-${index}`}>
                <Stage {...otherProps} />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <Button
          variant="contained"
          size="small"
          color="primary"
          sx={{
            ...styles.button,
            left: 0,
          }}
          className={`${SLIDING_STAGE_CLASS}-prev`}
          data-cy={`${SLIDING_STAGE_CLASS}-prev`}
        >
          <KeyboardArrowLeft sx={styles.icon} />
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          sx={{
            ...styles.button,
            right: 0,
          }}
          className={`${SLIDING_STAGE_CLASS}-next`}
          data-cy={`${SLIDING_STAGE_CLASS}-next`}
        >
          <KeyboardArrowRight sx={styles.icon} />
        </Button>
      </Box>
      <Box display="flex" justifyContent="center">
        <MobileStepper
          variant="dots"
          steps={MAX_NUMBER_OF_SLIDES}
          position="static"
          backButton={''}
          nextButton={''}
          sx={{ padding: '4px' }}
          data-cy={`${SLIDING_STAGE_CLASS}-stepper-dots`}
          onClick={() => onNavigationClick('dots', muiComponentsWithAnalytics, '')}
        />
      </Box>
    </Box>
  )
}

export default SlidingStage
