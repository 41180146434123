import { useStageProps } from '@components/Stage/useStageProps'

import type { SlidingStageContentfulProps, SlidingStageProps } from './SlidingStage.types'

export const useSlidingStageProps = (
  fields: SlidingStageContentfulProps['fields'],
): SlidingStageProps => {
  const { getStageProps } = useStageProps()

  const { stages, stageType } = fields || {}

  if (!stages) {
    return null
  }

  const stagesWithTypeOverride = stages
    ?.filter((stage) => stage.fields) // Filter out draft content from contentful
    .map((stage) => getStageProps({ ...stage, stageType }))

  return { stages: stagesWithTypeOverride }
}

export default useSlidingStageProps
