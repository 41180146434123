import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import type { TypeContentStageSkeleton } from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'

export enum STAGE_TYPES {
  FULL_STAGE = 'fullStage',
  BRAND_STAGE = 'brandStage',
  SPLIT_STAGE = 'splitStage',
}

export type StageType = `${STAGE_TYPES}`

export type CMSStageProps = {
  stageType?: StageType
} & ContentEntry<TypeContentStageSkeleton> &
  QaHook
