import { grey } from '@knauf-group/ct-designs/themeConfigs/rebranding/colors'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import { useTheme } from '@mui/material/styles'

export const useStyles = (isSingleSlide: boolean): Styles => {
  const theme = useTheme()

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: { xs: 2, sm: 3 },
      '& .swiper': {
        width: '100%',
        height: '100%',
      },
      '& .swiper-slide': {
        height: 'inherit',
      },
      '& .swiper-wrapper': {
        height: 'auto',
      },
      '& .swiper-pagination-bullet': {
        background: grey[300],
        borderRadius: 0,
      },
      '& .swiper-pagination-bullet-active': {
        background: theme.palette.text['brand-title'],
        width: '56px',
      },
      '& :hover.swiper-pagination-bullet': {
        background: grey[500],
      },
      '& :hover.swiper-pagination-bullet-active': {
        background: theme.palette.text['brand-title'],
      },
    },
    button: {
      display: { xs: 'none', lg: isSingleSlide ? 'none' : 'block' },
      position: 'absolute',
      top: '50%',
      zIndex: 1,
      minWidth: '40px',
      height: '40px',
      padding: '8px',
    },
    icon: {
      '&:dir(rtl)': {
        transform: 'rotate(180deg)',
      },
    },
  }
}
